/* Cau hinh danh sach field */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  Checkbox,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import { DataGrid, viVN } from "@mui/x-data-grid";
import { showError, showSuccess, handleServerError } from "../lib/common";
const server = require("../lib/server");

/* KHAI BAO MAIN FUNCTION */
export default function ColumnConfigForm(props) {
  const [dataEdit, setDataEdit] = React.useState({ ...props.ColumnConfig });
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj) {
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
  }

  return (
    <Dialog maxWidth={"lg"} open={true}>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            Cấu hình grid-column
          </Typography>
          <Stack direction="row" spacing={4}>
            <Button
              variant="contained"
              onClick={() => {
                props.save(dataEdit);
              }}
            >
              Lưu
            </Button>

            <Button
              color="success"
              variant="contained"
              onClick={() => props.close()}
            >
              Thoát
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 500,
            minHeight: 30,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
          }}
        >
          <Grid container spacing={1} columns={12}>
            <Grid item xs={4}>
              <FormLabel>Tên trường:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.field}
                onChange={(event) => saveField("field", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Tiêu đề:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.headerName}
                onChange={(event) =>
                  saveField("headerName", event.target.value)
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Header-align:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Select
                fullWidth
                value={dataEdit.headerAlign}
                onChange={(event) =>
                  saveField("headerAlign", event.target.value)
                }
              >
                <MenuItem value={"center"}>center</MenuItem>
                <MenuItem value={"left"}>left</MenuItem>
                <MenuItem value={"right"}>right</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Cell-align:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Select
                fullWidth
                value={dataEdit.align}
                onChange={(event) => saveField("align", event.target.value)}
              >
                <MenuItem value={"center"}>center</MenuItem>
                <MenuItem value={"left"}>left</MenuItem>
                <MenuItem value={"right"}>right</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Cho phép sắp xếp:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={dataEdit.sortable}
                onChange={(event) =>
                  saveField("sortable", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Cho phép Edit:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={dataEdit.editable}
                onChange={(event) =>
                  saveField("editable", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Bỏ menu:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={dataEdit.disableColumnMenu}
                onChange={(event) =>
                  saveField("disableColumnMenu", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Chiều rộng:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <MyNumberEditor
                fullWidth
                scale={0}
                value={dataEdit.width}
                onValueChange={(value) => saveField("width", value)}
              ></MyNumberEditor>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Format date:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.dateFormat}
                onChange={(event) =>
                  saveField("dateFormat", event.target.value)
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Format boolean:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={dataEdit.booleanFormat}
                onChange={(event) =>
                  saveField("booleanFormat", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Open link:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={dataEdit.webLink}
                onChange={(event) => saveField("webLink", event.target.checked)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>View file:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.viewFile}
                onChange={(event) => saveField("viewFile", event.target.value)}
              ></OutlinedInput>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}
