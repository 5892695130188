import HS_GiayChungNhan from "./HS_GiayChungNhan_Form";
import HS_GiayChungNhan_LichSu_Form from "./HS_GiayChungNhan_LichSu_Form";

export function CustomFormByName(name, props) {
  console.log(name);
  return name === "HS_GiayChungNhan_LichSu" ? (
    <HS_GiayChungNhan_LichSu_Form {...props} />
  ) : name === "HS_CanhBao_DoiTau" ? (
    <HS_GiayChungNhan {...props} />
  ) : null;
}
