/* Hien thi giao dien form tim kiem */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
} from "@mui/material";

import { ToastContainer } from "react-toastify";
import MyDateEditor from "../components/MyDateEditor";
import { showError, showSuccess, handleServerError, showWarning } from "../lib/common";

import EditFormConfig from "../DynamicForms/EditFormConfig";
import { HackFunctionList } from "../DynamicForms/FormHack";

import MyNumberEditor from "../components/MyNumberEditor";
import MySelectFilter from "../components/MySelectFilter";
import { FileAttachControl } from "../components/FileAttachControl";
import uuid from "react-uuid";
import { MultiFileAttachControl } from "../components/MultiFileAttachControl";
const server = require("../lib/server");
const myLib = require("../lib/MyLib");

/* KHAI BAO MAIN FUNCTION */
export default function HS_GiayChungNhan_ThayDoi_Form(props) {
  //cac bien dung chung trong form
  //cac bien cau hinh form
  let FormConfig = props.FormConfig; //khai bao cau hinh hien thi form
  let defaultValues = {}; //gia tri khoi tao
  let defaultDanhMuc = {};
  var hasDanhMuc = false;
  var danhMucNames = ""; //danh sach ten danh muc
  props.FormConfig.EditFields.map((field) => {
    if (field.DefaultValue !== undefined && field.DefaultValue !== null) {
      if (
        typeof field.DefaultValue === "string" &&
        field.DefaultValue.indexOf("@") === 0
      ) {
        if (field.DefaultValue == "@User.BranchCode") {
          field.DefaultValue = global.User.BranchCode; //ma don vi nguoi su dung
        }
        if (
          props.FilterData &&
          field.DefaultValue.indexOf("@FilterData.") == 0
        ) {
          //lay gia tri truyen vao tu props.FilterData
          var filterField = field.DefaultValue.split(".")[1];
          defaultValues[field.FieldName] = props.FilterData[filterField];
        }
      } else {
        if (field.DefaultValue === "thisyear()") {
          defaultValues[field.FieldName] = new Date().getFullYear();
          defaultValues[field.FieldName + "_Ten"] = new Date().getFullYear();
        } else {
          if (field.DataType == "Boolean") {
            defaultValues[field.FieldName] = field.DefaultValue + "" === "true";
          } else {
            if (
              field.DataType == "String" &&
              field.DefaultValue.indexOf("{") >= 0
            ) {
              var exp = field.DefaultValue.substring(
                1,
                field.DefaultValue.length - 1
              );
              var ps = exp.split(".");
              if (ps.length > 1 && ps[0] == "props") {
                if (ps.length > 2) {
                  defaultValues[field.FieldName] = props[ps[1]]
                    ? props[ps[1]][ps[2]]
                    : null; //lay tu props
                } else {
                  defaultValues[field.FieldName] = props[ps[1]]; //lay tu props
                }
              }
            } else {
              {
                if (field.DataType === "File") {
                  field.accept = myLib.getAcceptFileType(field.DefaultValue); //chuyen thanh danh sach file
                } else {
                  defaultValues[field.FieldName] = field.DefaultValue;
                }
              }
            }
          }
        }
      }
    }
    if (field.SourceName && field.SourceName !== "") {
      defaultDanhMuc[field.SourceName] = []; //khoi tao la array trong'
      hasDanhMuc = true;
      if (
        danhMucNames !== field.SourceName &&
        danhMucNames.indexOf(field.SourceName + ",") < 0 &&
        danhMucNames.indexOf("," + field.SourceName) < 0
      ) {
        danhMucNames += (danhMucNames !== "" ? "," : "") + field.SourceName;
      }
    }
  });
  const [showConfigForm, setShowConfigForm] = React.useState(false);
  //cac bien state tim kiem
  const [DanhMuc, setDanhMuc] = React.useState(defaultDanhMuc); // du lieu danh muc se su dung trong form
  const [danhMucDone, setDanhMucDone] = React.useState(false); // danh dau viec load danh muc da xong chua
  const [validateStatus, setValidateStatus] = React.useState({}); //du lieu validate data
  const [dataChanged, setDataChanged] = React.useState(false); //du lieu edit co thay doi hay khong
  const [dataEdit, setDataEdit] = React.useState(
    props.edittingRecord
      ? {
          ...defaultValues,
          ...props.edittingRecord,
          //gia tri mac dinh
          Attachs: [],
          SoGCN: null,
          NgayCap: null,
          NgayHetHan: null,
          NgayGiaHan: null,
          NgayCanhBao: null,
          LinkWeb: null,
          NoiKiemTra: null,
        } //phai convert sang json
      : defaultValues
  ); //cau truc du lieu dang edit
  var savingData = {}; //du lieu su dung de save vao csdl
  const [loading, setLoading] = React.useState(false); //trang thai loading du lieu
  const [saving, setSaving] = React.useState(false); //trang thai dang ghi du lieu
  //khai bao cac function su dung trong component
  const saveField = (fieldName, value) => {
    var newData = { ...dataEdit };
    var obj = {};
    obj[fieldName] = value;

    if (!myLib.isEmpty(props.FormConfig.EditOnChange)) {
      var func = HackFunctionList[props.FormConfig.EditOnChange];
      if (!func) {
        //create dynamic function
        func = new Function(
          "data",
          "dataEdit",
          "selectedItem",
          "DanhMuc",
          props.FormConfig.EditOnChange
        );
      }
      if (func) {
        console.log("call function:", func);
        obj = func(obj, dataEdit, null, DanhMuc);
      }
    }
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
    setDataChanged(true);
    //xoa trang thai validate
    setValidateStatus({ ...validateStatus, [fieldName]: undefined });
  };
  //ghi nhan tham so filter theo kieu data
  function saveData(obj, selectedItem) {
    //call function can thiep vao gia tri neu co
    if (!myLib.isEmpty(props.FormConfig.EditOnChange)) {
      var func = HackFunctionList[props.FormConfig.EditOnChange];
      if (!func) {
        //create dynamic function
        func = new Function(
          "data",
          "dataEdit",
          "selectedItem",
          "DanhMuc",
          props.FormConfig.EditOnChange
        );
      }

      if (func) {
        obj = func(obj, dataEdit, selectedItem, DanhMuc);
      }
    }
    var newData = { ...dataEdit, ...obj };
    if (obj.MaLoaiGCN) {
      newData.PhanLoaiGCN = selectedItem.PhanLoaiGCN; //thiet lap loai gcn
      //filter danh muc khi laoi gcn thay doi
      filterDanhMuc(newData);
    }
    setDataEdit(newData);
    setDataChanged(true);
  }
//thuc hien validate, tra ve du lieu sau khi validate
const validateData = () => {
  var newData = { ...dataEdit };
  var checkResults = {}; //ket qua validate
  props.FormConfig.EditFields.map((field) => {
    if (
      field.Nullable + "" === "false" &&
      myLib.isEmpty(newData[field.FieldName])
    ) {
      checkResults[field.FieldName] = true;
    }
    if (field.MinLength + "" !== "" && field.DataType === "String") {
      if ((newData[field.FieldName] + "").length < field.MinLength) {
        checkResults[field.FieldName] = true;
      }
    }
    if (
      !myLib.isEmpty(field.MinValue) &&
      (field.DataType === "Int" || field.DataType === "Decimal")
    ) {
      if (
        !myLib.isEmpty(newData[field.FieldName]) &&
        newData[field.FieldName] < field.MinValue
      ) {
        checkResults[field.FieldName] = true;
      }
    }
    //chuan hoa string
    if (
      field.DataType === "String" &&
      !myLib.isEmpty(newData[field.FieldName])
    ) {
      //kiem tra xem co su dung unicode to hop khong
      var temp1 = newData[field.FieldName];
      if (temp1) {
        temp1 = temp1 + "";
        temp1 = temp1.normalize("NFC"); //chuyen thanh ma code dung san~
        var temp2 = newData[field.FieldName] + "";
        if (temp1 !== temp2) {
          checkResults[field.FieldName] = true; //khong su dung Unicode dung san~ thi bao loi
          console.warn("Unicode dựng sẵn!");
        }
      }
      //kiem tra xem co ky tu dac biet khong
      if (field.TiengViet && !myLib.isVanBanTV(newData[field.FieldName])) {
        checkResults[field.FieldName] = true;
        console.warn("Ký tự không phải tiếng việt!");
      }
      if (field.ChuanHoa) {
        newData[field.FieldName] = myLib.ChuanHoaString(
          newData[field.FieldName]
        );
      }
    }
  });
  //kiem tra validate function neu co'
  if (Object.keys(checkResults).length == 0) {
    if (!myLib.isEmpty(props.FormConfig.ValidateFunctionBody)) {
      var func = new Function(
        "data",
        "myLib",
        props.FormConfig.ValidateFunctionBody
      );
      checkResults = func(newData, myLib);
    }
  }
  //ghi nho lai
  savingData = newData;
  return checkResults;
};
  //hook
  React.useEffect(() => {
    //thuc hien search on load form hoac khi co su thay doi ve phan trang va sap xep
    if (danhMucNames !== "" && !danhMucDone) {
      setLoading(true);
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhMucNames,
          },
        })
        .then((response) => {
          setLoading(false);
          setDanhMucDone(true);
          //xem co field nao su dung den danh muc khong
          //neu co danh muc nao filter theo truong thong tin khac thi tao danh muc do'
          var setValues = {};
          props.FormConfig.EditFields.map((field) => {
            if (field.SourceName && field.SourceName !== "") {
              var list = response[field.SourceName];

              //neu co gia tri default
              if (field.DefaultValue) {
                if (list.length > 0) {
                  if (field.DefaultValue == "@FirstRow") {
                    setValues = {
                      ...setValues,
                      [field.FieldName]: list[0][field.SourceValue],
                      [field.FieldName + "_Ten"]: list[0][field.SourceLabel],
                    };
                  }
                  //neu lay gia tri tu tieu chi tim kiem
                  if (
                    props.FilterData &&
                    typeof field.DefaultValue === "string" &&
                    field.DefaultValue.indexOf("@FilterData.") == 0
                  ) {
                    var filterField = field.DefaultValue.split(".")[1];
                    var filterValue = props.FilterData[filterField];
                    var found = list.find(
                      (item) => item[field.SourceValue] === filterValue
                    );
                    if (found) {
                      setValues = {
                        ...setValues,
                        [field.FieldName]: found[field.SourceValue],
                        [field.FieldName + "_Ten"]: found[field.SourceLabel],
                      };
                    }
                  }
                  if (field.DataType === "Int") {
                    var intValue = Number(field.DefaultValue);
                    var found = response[field.SourceName].find(
                      (item) => item[field.SourceValue] == intValue
                    );
                    if (found) {
                      setValues = {
                        ...setValues,
                        [field.FieldName]: intValue,
                        [field.FieldName + "_Ten"]: found[field.SourceLabel],
                      };
                    }
                  }
                }
              }
            }
          });
          var newEditValues = { ...dataEdit };
          //neu co thi gan gia tri neu khong o che do edit
          if (Object.keys(setValues).length > 0 && !props.edittingRecord) {
            saveData(setValues);
            newEditValues = { ...newEditValues, ...setValues }; //gop cac gia tri
          }
          //neu filter theo truong khac
          props.FormConfig.EditFields.map((field) => {
            if (field.SourceName && field.SourceName !== "") {
              if (field.SourceFilter) {
                var list = [];
                var all = response[field.SourceName];
                all.map((item) => {
                  if (
                    item[field.SourceFilter] ===
                    newEditValues[field.SourceFilterBy]
                  ) {
                    list.push(item);
                  }
                });
                response[field.SourceName + "_Filter"] = list; //tao danh muc filter
              }
            }
          });

          setDanhMuc(response);
        })
        .catch((error) => {
          handleServerError(error);
          setLoading(false);
        });
    }
  }, []);
  const filterDanhMuc = (newDataEdit) => {
    if (danhMucDone) {
      //xu ly khi can phai loc danh muc
      var newDanhMuc = { ...DanhMuc };
      var changed = false;
      props.FormConfig.EditFields.map((field) => {
        if (field.SourceName && field.SourceName !== "") {
          if (field.SourceFilter) {
            var list = [];
            var all = newDanhMuc[field.SourceName];
            all.map((item) => {
              if (
                item[field.SourceFilter] === newDataEdit[field.SourceFilterBy]
              ) {
                list.push(item);
              }
            });
            newDanhMuc[field.SourceName + "_Filter"] = list; //tao danh muc filter
            changed = true;
          }
        }
      });
      if (changed) {
        setDanhMuc(newDanhMuc);
      }
    }
  };
  //thuc hien doc noi dung 1 file
  const doReadOneFile = (fileObject) => {
    //thuc hien doc tiep cac file attachs neu co
    var prom = new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const raw = Buffer.from(text).toString("base64");
        doSaveUpload({
          FileID: uuid(),
          FileName: fileObject.name,
          FileContent: raw,
        });
      };
      fr.onerror = reject;
      fr.readAsArrayBuffer(fileObject);
    });
    //thuc hien viec xu ly
    prom
      .then((fileData) => {
        //thuc hien upload
        doSaveUpload(fileData);
      })
      .catch((e) => showError(e));
  };
  //thuc hien doc nhieu file sau do submit len server
  const doReadAttachs = (attachs) => {
    var listProms = [];
    attachs.map((item) => {
      if (item.FileObject) {
        var prom = new Promise((resolve, reject) => {
          var fr = new FileReader();
          fr.onload = async (e) => {
            const text = e.target.result;
            const raw = Buffer.from(text).toString("base64");
            resolve({
              FileID: uuid(),
              FileName: item.FileObject.name,
              FileContent: raw,
            });
          };
          fr.onerror = reject;
          fr.readAsArrayBuffer(item.FileObject);
        });
        listProms.push(prom); //cho vao array
      } else {
        listProms.push(item); //giu lai thong tin FileID, FileName
      }
    });
    //chay tat ca promiese
    setSaving(true);
    Promise.all(listProms)
      .then((results) => {
        doSaveUploads(results);
      })
      .catch((error) => {
        showError(error);
        setSaving(false);
      });
  };
  //thuc hien cap nhat voi 1 file dinh kem
  const doSaveUpload = (fileData) => {
    console.log(fileData);
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: FormConfig.SaveFunction,
        ThamSo: {
          ...savingData,
          FileObject: null,
          ...fileData,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        if (FormConfig.CloseAfterSaving) {
          props.close(true);
        }
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  //thuc hien cap nhat nhieu file dinh kem
  const doSaveUploads = (uploads) => {
    server
      .post("Data/DoRequest", {
        Function: FormConfig.SaveFunction,
        ThamSo: {
          ...savingData,
          Attachs: uploads,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        if (FormConfig.CloseAfterSaving) {
          props.close(true);
        }
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  //ham xu ly viec save du lieu
  const doSaveNoFile = () => {
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: FormConfig.SaveFunction,
        ThamSo: {
          ...savingData,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        if (FormConfig.CloseAfterSaving) {
          props.close(true);
        }
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  //thuc hien cap nhat
  const doSave = () => {
    var checks = validateData();
    console.log(checks);
    if (Object.keys(checks).length == 0) {
      if (dataEdit.FileObject) {
        doReadOneFile(dataEdit.FileObject);
      } else {
        if (dataEdit.Attachs && dataEdit.Attachs.length) {
          doReadAttachs(dataEdit.Attachs);
        } else {
          doSaveNoFile();
        }
      }
    } else {
      //hien thi trang thai validate
      setValidateStatus(checks);
      showWarning("Đề nghị kiểm tra dữ liệu nhập!");
    }
  };
  //ham xu ly viec close form
  const doClose = () => {
    props.close(false);
  };
  return (
    <Dialog maxWidth={FormConfig.DialogWidth ?? "lg"} open={true} fullWidth>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            {FormConfig.FormTitle}
          </Typography>
          <Stack direction="row" spacing={4}>
            {loading ? <CircularProgress color="warning" size={24} /> : null}
            {saving ? <CircularProgress color="inherit" size={24} /> : null}
            {global.hasRoles("KHTC.RX") ? (
              <Button
                variant="contained"
                onClick={() => setShowConfigForm(true)}
              >
                Config
              </Button>
            ) : null}
            <Button
              variant="contained"
              disabled={!dataChanged || saving}
              onClick={doSave}
            >
              Save
            </Button>

            <Button
              color="success"
              variant="contained"
              disabled={saving}
              onClick={doClose}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 400,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
            overflowY: "hidden",
            minHeight: FormConfig.MinHeight ?? 300,
          }}
        >
          <Grid container spacing={1} columns={24}>
            {props.FormConfig.EditFields.map((field) =>
              field.Hidden ? null : (
                <>
                  {field.LabelWidth ? (
                    <Grid item xs={field.LabelWidth}>
                      <FormLabel>{field.Label}</FormLabel>
                    </Grid>
                  ) : null}
                  {field.InputWidth ? (
                    <Grid item xs={field.InputWidth ?? 4}>
                      {field.SourceName && field.SourceName !== "" ? (
                        <MySelectFilter
                          fullWidth
                          autoFocus={field.AutoFocus}
                          options={
                            DanhMuc[
                              field.SourceFilter
                                ? field.SourceName + "_Filter"
                                : field.SourceName
                            ]
                          }
                          disabled={
                            props.edittingRecord ? field.LockEdit : false
                          }
                          optionLabel={field.SourceLabel}
                          optionValue={field.SourceValue}
                          value={{
                            [field.SourceValue]: dataEdit[field.FieldName],
                            [field.SourceLabel]:
                              dataEdit[field.FieldName + "_Ten"] ??
                              dataEdit[field.FieldName],
                          }}
                          onChange={(value, selectedItem) => {
                            var setValues = {
                              [field.FieldName]:
                                value !== null
                                  ? value[field.SourceValue]
                                  : null,
                              [field.FieldName + "_Ten"]:
                                value !== null
                                  ? value[field.SourceLabel]
                                  : null,
                            };

                            //kiem tra xem co phai auto load lai danh sach combo khac phu thuoc vao truong nay khong
                            var found = props.FormConfig.EditFields.find(
                              (item) =>
                                item.SourceFilterAuto &&
                                item.SourceFilterBy === field.FieldName
                            );
                            if (found) {
                              //xoa truong thong tin
                              setValues = {
                                ...setValues,
                                ...{
                                  [found.FieldName]: null,
                                  [found.FieldName + "_Ten"]: null,
                                },
                              };
                              //thiet lap lai du lieu danh muc filter
                              var list = [];
                              var all = DanhMuc[found.SourceName];
                              var filterValue =
                                value !== null
                                  ? value[field.SourceValue]
                                  : null;
                              all.map((item) => {
                                if (item[found.SourceFilter] === filterValue) {
                                  list.push(item);
                                }
                              });
                              DanhMuc[found.SourceName + "_Filter"] = list; //tao danh muc filter
                            }
                            saveData(setValues, selectedItem);
                          }}
                          error={validateStatus[field.FieldName] !== undefined}
                        />
                      ) : field.DataType === "Date" ? (
                        <MyDateEditor
                          fullWidth
                          autoFocus={field.AutoFocus}
                          readOnly={field.ReadOnly}
                          disabled={
                            props.edittingRecord ? field.LockEdit : false
                          }
                          value={dataEdit[field.FieldName]}
                          onChange={(value) => {
                            saveField(field.FieldName, value);
                          }}
                          error={validateStatus[field.FieldName] !== undefined}
                        />
                      ) : field.DataType === "String" ? (
                        <OutlinedInput
                          fullWidth
                          autoFocus={field.AutoFocus}
                          disabled={
                            props.edittingRecord ? field.LockEdit : false
                          }
                          value={dataEdit[field.FieldName]}
                          readOnly={field.ReadOnly}
                          multiline={field.MultiLine}
                          onChange={(event) => {
                            saveField(
                              field.FieldName,
                              field.UpperCasing
                                ? myLib.toUpperCase(event.target.value)
                                : event.target.value
                            );
                          }}
                          error={validateStatus[field.FieldName] !== undefined}
                        ></OutlinedInput>
                      ) : field.DataType === "Boolean" ? (
                        <Checkbox
                          size="medium"
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                          checked={dataEdit[field.FieldName]}
                          readOnly={true}
                          onChange={(event) => {
                            if (!field.ReadOnly) {
                              saveField(field.FieldName, event.target.checked);
                            }
                          }}
                        />
                      ) : field.DataType === "Int" ? (
                        <MyNumberEditor
                          fullWidth
                          scale={0}
                          disabled={
                            props.edittingRecord ? field.LockEdit : false
                          }
                          value={dataEdit[field.FieldName]}
                          onValueChange={(value) =>
                            saveField(field.FieldName, value)
                          }
                          error={validateStatus[field.FieldName] !== undefined}
                        ></MyNumberEditor>
                      ) : field.DataType === "File" ? (
                        <FileAttachControl
                          data={dataEdit}
                          accept={field.accept}
                          maxSize={field.MaxLength}
                          disabled={
                            props.edittingRecord ? field.LockEdit : false
                          }
                          onChange={(files) => {
                            if (files) {
                              if (!files.length) {
                                saveData({
                                  FileName: files[0].name,
                                  FileObject: files[0],
                                });
                              } else {
                                console.log(files);
                                saveData({
                                  FileName: files[0].name,
                                  FileObject: files[0],
                                });
                              }
                            } else {
                              saveData({
                                FileID: null,
                                FileName: null,
                                FileObject: null,
                              });
                            }
                          }}
                          error={validateStatus[field.FieldName] !== undefined}
                        />
                      ) : field.DataType === "MultiFile" ? (
                        <MultiFileAttachControl
                          attachs={
                            dataEdit[field.FieldName]
                              ? dataEdit[field.FieldName]
                              : []
                          }
                          accept={field.accept}
                          maxSize={field.MaxLength}
                          disabled={
                            props.edittingRecord ? field.LockEdit : false
                          }
                          onChange={(files, index) => {
                            console.log(files);
                            if (files) {
                              if (!files.length) {
                                //chi co 1 file
                                var list = dataEdit[field.FieldName]
                                  ? dataEdit[field.FieldName]
                                  : [];
                                if (index == 0 && list.length > 1) {
                                  //cho vao dau tien
                                  list.splice(0, 0, {
                                    FileID: uuid(),
                                    FileName: files[0].name,
                                    FileObject: files[0],
                                  });
                                } else {
                                  //cho vao cuoi danh sach
                                  list.push({
                                    FileID: uuid(),
                                    FileName: files[0].name,
                                    FileObject: files[0],
                                  });
                                }
                                saveField(field.FieldName, list);
                              } else {
                                //danh sach nhieu file
                                var list = dataEdit[field.FieldName]
                                  ? dataEdit[field.FieldName]
                                  : [];
                                if (index == 0 && list.length > 1) {
                                  //cho vao dau
                                  files.map((item, idx) =>
                                    list.splice(idx, 0, {
                                      FileID: uuid(),
                                      FileName: item.name,
                                      FileObject: item,
                                    })
                                  );
                                } else {
                                  //cho vao duoi
                                  list = list.concat(
                                    files.map((item) => ({
                                      FileID: uuid(),
                                      FileName: item.name,
                                      FileObject: item,
                                    }))
                                  );
                                }
                                saveField(field.FieldName, list);
                              }
                            } else {
                              var list = dataEdit[field.FieldName];

                              if (list && list.length) {
                                list.splice(index, 1);
                                saveField(field.FieldName, list);
                              }
                            }
                          }}
                          onRemove={(file, index) => {
                            var list = dataEdit[field.FieldName];
                            if (file.FileObject) {
                              //file local thi remove
                              list.splice(index, 1);
                              saveField(field.FieldName, [].concat(list));
                            } else {
                              //file da co trong DB
                              file.FileName = null; //gan = null de submit len thi xoa khoi DB
                              saveField(field.FieldName, [].concat(list));
                            }
                          }}
                          deleteFileAction={(fileID) => {
                            //xoa file
                          }}
                          error={validateStatus[field.FieldName] !== undefined}
                        />
                      ) : null}
                    </Grid>
                  ) : null}
                </>
              )
            )}
          </Grid>
        </div>
      </DialogContent>
      {
        /**Hien thi form cau hinh form edit */
        showConfigForm ? (
          <EditFormConfig
            FormID={props.FormID}
            FormConfig={FormConfig}
            close={() => setShowConfigForm(false)}
          />
        ) : null
      }
    </Dialog>
  );
}
