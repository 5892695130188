/* Cau hinh danh sach field */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  Checkbox,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import { DataGrid, viVN } from "@mui/x-data-grid";
import { showError, showSuccess, handleServerError } from "../lib/common";
import FieldConfigForm from "./FieldConfigForm";
import { MyLocation } from "@mui/icons-material";
const server = require("../lib/server");
const myLib = require("../lib/MyLib");

/* KHAI BAO MAIN FUNCTION */
export default function EditFormConfig(props) {
  const [saving, setSaving] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState({ ...props.FormConfig });
  const [dataChanged, setDataChanged] = React.useState(false);
  const [editFields, setEditFields] = React.useState(
    props.FormConfig.EditFields
  ); //danh sach column cua grid

  const [showFieldConfig, setShowFieldConfig] = React.useState(false); //hien thi form config column
  const [selectedField, setSelectedField] = React.useState(null); // dang edit column nao
  //danh sach cac nut button
  const [actionButtons, setActionButtons] = React.useState(
    props.FormConfig.ActionButtons
  );
  const [showConfigButton, setShowConfigButton] = React.useState(false);
  const [selectedActionButton, setSelectedActionButton] = React.useState(null); // dang config button nao
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj) {
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
  }
  //ham xu ly viec save du lieu
  const doSave = () => {
    //chuan hoa lai gia tri defaultvalue cua cac field
    dataEdit.EditFields.map(
      (field) =>
        (field.DefaultValue =
          field.DataType === "Boolean" &&
          !myLib.isEmpty(field.DefaultValue)
            ? (field.DefaultValue + "" === "true")
            : field.DefaultValue)
    );
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DA_Forms_Update",
        ThamSo: {
          FormID: props.FormID,
          EditFields: editFields,
          FormTitle: dataEdit.FormTitle,
          DialogWidth: dataEdit.DialogWidth,
          SaveFunction: dataEdit.SaveFunction,
          CloseAfterSaving: dataEdit.CloseAfterSaving,
          MinHeight: dataEdit.MinHeight,
          EditOnChange: dataEdit.EditOnChange,
          ValidateFunctionBody: dataEdit.ValidateFunctionBody,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        props.close(false);
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  return (
    <Dialog maxWidth={"xl"} open={true}>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            Cấu hình form edit {props.FormID}
          </Typography>
          <Stack direction="row" spacing={4}>
            <Button
              variant="contained"
              onClick={() => {
                doSave();
              }}
            >
              Lưu
            </Button>

            <Button
              color="success"
              variant="contained"
              onClick={() => props.close()}
            >
              Thoát
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 500,
            minHeight: 30,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
          }}
        >
          <Grid container spacing={1} columns={12}>
            <Grid item xs={4}>
              <FormLabel>Tiêu đề:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.FormTitle}
                onChange={(event) => saveField("FormTitle", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Dialog-width:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.DialogWidth}
                onChange={(event) =>
                  saveField("DialogWidth", event.target.value)
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Min-height:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <MyNumberEditor
                fullWidth
                value={dataEdit.MinHeight}
                onValueChange={(value) =>
                  saveField("MinHeight", value)
                }
              ></MyNumberEditor>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Close after saving:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.CloseAfterSaving}
                onChange={(event) =>
                  saveField("CloseAfterSaving", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <FormLabel>Procedure save:</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <OutlinedInput
                fullWidth
                value={dataEdit.SaveFunction}
                onChange={(event) =>
                  saveField("SaveFunction", event.target.value)
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
          {/**Danh sach field */}
          <Grid container columns={12} spacing={1}>
            <Grid item xs={12}>
              <strong>Danh sách trường:</strong>
            </Grid>
            {editFields.map((field, index) => (
              <>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setShowFieldConfig(true);
                      setSelectedField(field);
                    }}
                  >
                    {field.FieldName}
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(editFields);
                      list.splice(index, 1);
                      setEditFields(list);
                      setDataChanged(true);
                    }}
                  >
                    Xóa
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(editFields);
                      list.splice(index, 1);
                      if (index == 0) {
                        list.push(field);
                      } else {
                        list.splice(index - 1, 0, field);
                      }
                      setEditFields(list);
                      setDataChanged(true);
                    }}
                  >
                    Lên
                  </Button>
                </Grid>
              </>
            ))}
            <Grid item xs={12}>
              <Button
                color="success"
                onClick={() => {
                  var list = [].concat(editFields);
                  list.push({
                    LabelWidth: 6,
                    InputWidth: 18,
                    MinLength: 0,
                    MaxLength: null,
                    MinValue: null,
                    MaxValue: null,
                    Nullable: true,
                  });
                  setEditFields(list);
                  setDataChanged(true);
                }}
              >
                Thêm
              </Button>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Edit onchanges:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                multiline
                value={dataEdit.EditOnChange}
                onChange={(event) => saveField("EditOnChange", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Validate function body[data]:</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <OutlinedInput
                fullWidth
                multiline
                value={dataEdit.ValidateFunctionBody}
                onChange={(event) => saveField("ValidateFunctionBody", event.target.value)}
              ></OutlinedInput>
            </Grid>
          </Grid>
        </div>
        {
          /**Hien thi form config column
           */
          showFieldConfig ? (
            <FieldConfigForm
              FieldConfig={selectedField}
              save={(field) => {
                //tim truong va thay the
                var list = [].concat(editFields);
                var index = list.findIndex(
                  (item) => item.FieldName === selectedField.FieldName
                );
                list.splice(index, 1, field);
                setEditFields(list);
                setShowFieldConfig(false);
                setDataChanged(true);
              }}
              close={() => setShowFieldConfig(false)}
            />
          ) : null
        }
      </DialogContent>
    </Dialog>
  );
}
