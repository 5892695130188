/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
  LinearProgress,
} from "@mui/material";

import localStorage from "local-storage";
import uuid from "react-uuid";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../lib/common";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MySelectFilter from "../components/MySelectFilter";
import AsyncSelect from "react-select/async";
import MySelect from "../components/MySelect";
 
import {
  Add,
  Clear,
  Delete,
  Edit,
  Shower,
  SyncAlt,
  ThirtyFpsSelect,
} from "@mui/icons-material";
import BackupIcon from "@mui/icons-material/Backup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SimpleDialog from "../components/SimpleDialog";
import SimpleInputDialog from "../components/SimpleInputDialog";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";



const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const defaultValueFunctions = {
  ToDay: () => new Date(),
};
function validate_Data(data) {
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
//tao control cho tung field tim kiem
function createTieuChi(field, props, state, saveField, saveData, autoFocus) {
  var source = field.ListName;
  source = source
    ? source.indexOf("(") > 0
      ? source.substring(0, source.indexOf("("))
      : source
    : source;
  return (
    <>
      <Grid item xs={field.LabelWidth ? field.LabelWidth : 4}>
        <FormLabel>{field.Label}</FormLabel>
      </Grid>
      <Grid item xs={field.FieldInputWidth ? field.FieldInputWidth : 20}>
        {!myLib.isEmpty(field.ListName) ? (
          <MySelectFilter
            id={field.FieldName}
            fullWidth
            autoFocus={autoFocus}
            options={state.DanhMuc[source] ? state.DanhMuc[source] : []}
            optionLabel={field.SourceLabel}
            optionValue={field.SourceValue}
            placeholder=""
            value={
              field.DataType.indexOf("Array") >= 0
                ? state.after[field.FieldName]
                : state.after
            }
            isMulti={field.DataType.indexOf("Array") >= 0}
            onChange={(data, option) => {
              if (field.DataType.indexOf("Array") >= 0) {
                state.after[field.FieldName] = data ? data : [];
              } else {
                state.after[field.FieldName] = data
                  ? data[field.SourceValue]
                  : null;
                state.after[field.SourceLabel] = data
                  ? data[field.SourceLabel]
                  : null;
              }
              saveField("__version", 1);
            }}
          ></MySelectFilter>
        ) : field.DataType == "String" ? (
          <OutlinedInput
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(event) => {
              saveField(field.FieldName, event.target.value);
            }}
          />
        ) : field.DataType == "Date" ? (
          <MyDateEditor
            fullWidth
            autoFocus={autoFocus}
            readOnly={field.ReadOnly}
            value={state.after[field.FieldName]}
            onChange={(value) => {
              saveField(field.FieldName, value);
            }}
          />
        ) : field.DataType == "DateTime" ? (
          <MyDateTimeEditor
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(value) => {
              saveField(field.FieldName, value);
            }}
          />
        ) : field.DataType == "Bit" ? (
          <FormControlLabel
            label={""}
            labelPlacement="end"
            fullWidth
            control={
              <Checkbox
                size="medium"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                checked={state.after[field.FieldName]}
                onChange={(event) => {
                  saveField(field.FieldName, event.target.checked);
                }}
              />
            }
          />
        ) : field.DataType == "Int" ? (
          <MyNumberEditor
            fullWidth
            decimalScale={0}
            value={state.after[field.FieldName]}
            onValueChange={(value) => saveField(field.FieldName, value)}
          />
        ) : null}
      </Grid>
      {field.SpaceWidth ? (
        <Grid item xs={field.SpaceWidth}></Grid>
      ) : (
        <Grid item xs={1}></Grid>
      )}
    </>
  );
}
//lay gia tri de truyen ve server
function getTieuChi(after, configFields) {
  var newAfter = myLib.cloneObject(after);
  configFields.map((field) => {
    if (field.DataType.indexOf("Array") >= 0) {
      var list = [];
      var giatri = after[field.FieldName];
      if (giatri && giatri.length > 0) {
        giatri.map((gt) => list.push(gt[field.SourceValue]));
      }
      newAfter[field.FieldName] = list.length == 0 ? null : list;
    } else {
      newAfter[field.FieldName] = after[field.FieldName];
    }
  });
  return newAfter;
}
//////////////////////////////////////////////---------------///////////////////////////////////////////////////////
export default class ShowReportFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {
        DM_ReportFile: [],
      },
      FormConfig: null,
      uploading: false,
      after: {
        ReportFormID: 0,
        ...props.edittingRecord,
        ReportFormConfig: {
          Fields: [],
        },
      },
      hasThem: global.hasRoles("CVHH.R341"),
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }

  loadDanhMuc() {
    //load form config
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_ReportForm_GetOne",
        ThamSo: {
          ReportFormID: this.state.after.ReportFormID,
        },
      })
      .then((response) => {
        let formConfig = JSON.parse(response.DM_ReportForm[0].ReportFormConfig);
        //gan gia tri mac dinh neu co lay tu props hoac state
        formConfig.Fields.map((field) => {
          if (field.ListName == "{props}") {
            this.state.after[field.FieldName] = this.props[field.FieldName];
          }
          if (field.ListName == "{state}") {
            this.state.after[field.FieldName] = this.state[field.FieldName];
          }
          if (field.DefaultValue && field.DefaultValue !== "") {
            if (field.DataType == "Date") {
              if (field.DefaultValue == "today()") {
                let now = new Date();
                this.state.after[field.FieldName] = new Date(
                  now.getFullYear(),
                  now.getMonth(),
                  now.getDate()
                );
              }
              if (field.DefaultValue == "thismonth()") {
                let now = new Date();
                this.state.after[field.FieldName] = new Date(
                  now.getFullYear(),
                  now.getMonth(),
                  1
                );
              }
            }
            if(field.DataType === "Int") {
              this.state.after[field.FieldName] = field.DefaultValue;
            }
            if(field.DataType === "String") {
              this.state.after[field.FieldName] = field.DefaultValue;
              if (field.DefaultValue == "year()") {
                let now = new Date();
                this.state.after[field.FieldName] = now.getFullYear();
              }
            }
            if(field.DataType === "Bit" || field.DataType === "Boolean") {
              this.state.after[field.FieldName] = (field.DefaultValue + "") === "true";
            }
          }
        });
        this.setState({
          saving: false,
          ReportFormConfig: formConfig,
        });
      })
      .catch((error) => {
        this.setState({ saving: false });
        handleServerError(error);
      });
    //load thong tin du lieu cho report
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_ReportForm_GetForm",
        ThamSo: {
          ReportFormID: this.state.after.ReportFormID,
          ShipID: this.props.ShipData.ShipID,
        },
      })
      .then((response) => {
        this.state.DanhMuc = {
          ...this.state.DanhMuc,
          ...response,
        };
        this.setState({
          saving: false,
        });
      })
      .catch((error) => {
        this.setState({ saving: false });
        handleServerError(error);
      });
    //lay danh sach files mau rdlc
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_ReportFile_GetBy",
        ThamSo: {
          ReportFormID: this.state.after.ReportFormID,
        },
      })
      .then((response) => {
        this.state.DanhMuc.DM_ReportFile = response.DM_ReportFile;
        var defaultFile = localStorage.get(
          "ReportFile." + this.state.after.ReportFormID
        );
        defaultFile = defaultFile
          ? defaultFile
          : response.DM_ReportFile.length > 0
          ? response.DM_ReportFile[0].ReportFileID
          : "";
        this.setState({
          saving: false,
          after: {
            ...this.state.after,
            ReportFileID: defaultFile + "",
          },
        });
      })
      .catch((error) => {
        this.setState({ saving: false });
        handleServerError(error);
      });
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        localStorage.set(
          "ReportFile." + this.state.after.ReportFormID,
          this.state.after.ReportFileID
        );

        console.log(this.state.after);
        var thamso = getTieuChi(
          this.state.after,
          this.state.ReportFormConfig.Fields
        );

        console.log(thamso);
        var ps = encodeURIComponent(JSON.stringify(thamso));
        window.open(
          server.getServer() +
            "pages/ShowReport.aspx?" +
            "thamso_json=" +
            ps +
            "&_t=" +
            new Date().getTime() +
            "&sessionId=" +
            global.User.RandomID,
          "_blank"
        );
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  readOneFile(file) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (file) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          let fileData = {};
          fileData.ReportFileID = uuid();
          fileData.FileName = file.name;
          fileData.Content = Buffer.from(text).toString("base64");

          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      } else {
        reject(null);
      }
    });
  }
  doUpload(files) {
    this.readOneFile(files[0])
      .then((fileData) => {
        console.log(fileData);
        //thuc hien up load file
        this.setState({ uploading: true });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_ReportFile_Update",
            ThamSo: {
              ReportFormID: this.state.after.ReportFormID,
              ...fileData,
            },
          })
          .then((response) => {
            this.state.DanhMuc.DM_ReportFile = response.DM_ReportFile;
            this.state.after.ReportFileID = fileData.ReportFileID;
            this.setState({ uploading: false });
          })
          .catch((error) => {
            this.setState({ uploading: false });
            handleServerError(error);
          });
      })
      .catch((e) => {
        this.setState({ uploading: false });
        showError(e);
      });
  }

  render() {
    console.log(this.state.after);
    let xxx = this.state.after.ReportFileID;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            this.state.after.ReportFormTitle}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={26}>
              {this.state.ReportFormConfig &&
              this.state.ReportFormConfig.Fields ? (
                <>
                  {this.state.ReportFormConfig.Fields.map((field, index) =>
                    field.Visible ? (
                      <>
                        {createTieuChi(
                          field,
                          this.props,
                          this.state,
                          this.saveField,
                          this.saveData,
                          index == 0
                        )}
                      </>
                    ) : null
                  )}
                </>
              ) : null}
              <Grid item xs={26}>
                <FormLabel style={{ fontWeight: "bolder" }}>
                  Chọn biểu mẫu:
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Biểu mẫu:</FormLabel>
              </Grid>
              <Grid item xs={this.state.hasThem ? 16 : 20}>
                <MySelect
                  id="cboMau"
                  options={this.state.DanhMuc.DM_ReportFile}
                  optionLabel="FileName"
                  optionValue="ReportFileID"
                  value={this.state.after.ReportFileID + ""}
                  isClearable
                  onChange={(event) => {
                    this.saveField("ReportFileID", event.target.value);
                  }}
                ></MySelect>
              </Grid>
              {this.state.hasThem ? (
                <>
                  <Grid item xs={1}>
                    <IconButton
                      disabled={this.state.after.ReportFileID === ""}
                      sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                      onClick={() =>
                        this.setState({ open_edit_filename: true })
                      }
                    >
                      <Edit color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      disabled={this.state.after.ReportFileID === 0}
                      sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                      onClick={() => this.setState({ showConfirmXoa: true })}
                    >
                      <Clear color="error" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      disabled={this.state.after.ReportFileID === 0}
                      sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                      onClick={() =>
                        window.open(
                          server.getRequest(
                            "Data/GetFile?fileID=" +
                              this.state.after.ReportFileID
                          ),
                          "_blank"
                        )
                      }
                    >
                      <FileDownloadIcon color="success" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    {this.state.uploading ? (
                      <CircularProgress color="success" size={24} />
                    ) : (
                      <Dropzone
                        maxFiles={1}
                        multiple={false}
                        onDrop={(files) => this.doUpload(files)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <IconButton
                              disabled={
                                this.state.after.ReportFileID === 0 ||
                                this.state.uploading
                              }
                              sx={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                padingLeft: 1,
                              }}
                            >
                              <BackupIcon color="success" />
                            </IconButton>
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={24}>
                {this.state.saving ? (
                  <LinearProgress color="inherit" size={28} />
                ) : null}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {this.state.after.ReportFormCode === "KT.PORTLOG" ? (
            <Button
              disabled={this.state.saving || myLib.isEmpty(this.state.after.TripCode)}
              onClick={() => {
                this.setState({
                    show_edit_Portlog: true,
                  });
              }}
              variant="contained"
            >
              Port log
            </Button>
          ) : null}
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Xem báo cáo
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        
        
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa ?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_DM_ReportFile_Delete",
                      ThamSo: {
                        ReportFormID: this.state.after.ReportFormID,
                        ReportFileID: this.state.after.ReportFileID,
                      },
                    })
                    .then((response) => {
                      //reload
                      this.state.DanhMuc.DM_ReportFile = response.DM_ReportFile;
                      this.state.after.ReportFileID =
                        response.DM_ReportFile.length > 0
                          ? response.DM_ReportFile[0].ReportFileID
                          : "";
                      this.setState({ showConfirmXoa: false, saving: false });
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.open_edit_filename ? (
          <SimpleInputDialog
            confirm
            title="Sửa biểu mẫu"
            message="Nhập tên biểu mẫu mới:"
            defaultValue={this.state.after.FileName}
            open={true}
            OKRunning={this.state.saving}
            close={(ok, input) => {
              if (!this.state.saving) {
                if (ok && input !== "") {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_DM_ReportFile_UpdateName",
                      ThamSo: {
                        ReportFormID: this.state.after.ReportFormID,
                        ReportFileID: this.state.after.ReportFileID,
                        FileName: input,
                      },
                    })
                    .then((response) => {
                      //reload
                      this.state.DanhMuc.DM_ReportFile = response.DM_ReportFile;
                      this.state.after.ReportFileID =
                        response.DM_ReportFile.length > 0
                          ? response.DM_ReportFile[0].ReportFileID
                          : "";
                      this.setState({
                        open_edit_filename: false,
                        saving: false,
                      });
                    })
                    .catch((error) => {
                      this.setState({
                        saving: false,
                        open_edit_filename: false,
                      });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ open_edit_filename: false });
                }
              }
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
