import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  FormControl,
  Typography,
  Link,
  ListItemButton,
} from "@mui/material";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  ReactDataGrid_default,
  showError,
} from "../lib/common";
 

import ShowReportFormDialog from "./ShowReportFormDialog";
import ReportFormEditDialog from "./ReportFormEditDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/

/*-----------------------Dinh nghia cac column trong grid -----------------*/

////////////////////////////////////////////////////////////////////////////
export default class ReportingPage extends React.Component {
  constructor(props) {
    super(props);

    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      DanhMuc: {
        DM_ReportForm: [],
      },
      IsAdmin: global.hasRoles("Admin") || global.hasRoles("System"),
    };
  }

  //su kien form load
  componentDidMount() {
    this.loadDanhMuc(() => {});
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {}
  //load du lieu danh muc theo danh sach table
  loadDanhMuc() {
    //load danh muc
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_ReportForm_GetAll",
        ThamSo: {},
      })
      .then((response) => {
        var list = response.DM_ReportForm;
        var newList = [];
        list.map((report) => {
          //console.log(report, global.hasRoles(report.RightID));
          if (
            report.RightID == null ||
            report.RightID === "" ||
            global.hasRoles(report.RightID)
          ) {
            newList.push(report);
            report.ReportFormTitle =
              newList.length + ". " + report.ReportFormTitle;
          }
        });
        //console.log(newList);
        this.setState({
          DanhMuc: {
            ...this.state.DanhMuc,
            DM_ReportForm: newList,
          },
        });
      })
      .catch((error) => {
        showError(error);
      });
  }

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            {this.state.DanhMuc.DM_ReportForm.map((form) => (
              <>
                <Grid item xs={10}>
                  <ListItemButton
                    fullWidth
                    onClick={() => {
                      this.setState({
                        show_view_reportform: true,
                        edittingRecord: form,
                      });
                    }}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      this.setState({
                        show_edit_reportform: this.state.IsAdmin,
                        edittingRecord: form,
                      });
                    }}
                  >
                    {form.ReportFormTitle}
                  </ListItemButton>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid container spacing={2} columns={24}>
            <Grid item xs={22}></Grid>
            <Grid item xs={2}>
              {this.state.IsAdmin ? (
                <Button
                  onClick={() => {
                    this.setState({ show_edit_reportform: true });
                  }}
                >
                  +
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
        {this.state.show_edit_reportform ? (
          <ReportFormEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ show_edit_reportform: false, edittingRecord: null }, () =>
                this.loadDanhMuc()
              );
            }}
          />
        ) : null}
        {this.state.show_view_reportform ? (
          <ShowReportFormDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            ShipData={this.props.ShipData}
            ShipID={this.props.ShipData.ShipID}
            close={() => {
              this.setState({ show_view_reportform: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}

