import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  InputBase,
  DialogTitle,
  CircularProgress,
  LinearProgress,
  Grid,
} from "@mui/material";
import { Typography } from "antd";
export default function DoingConfirmDialog({
  handleDeleteAction,
  handleCloseAction,
  message,
  title,
  isDoing,
}) {
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
          display: "flex",
          alignItems: "center",
        }}
        id="draggable-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            minWidth: 450,
            minHeight: 30,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
          }}
        >
          <Typography style={{color: "black"}}>{message}</Typography>
        </div>
      </DialogContent>
      {isDoing ? <LinearProgress size={24} color="success" /> : null}

      <DialogActions>
        <Button
          onClick={() => handleDeleteAction()}
          variant="contained"
          autoFocus
          disabled={isDoing}
        >
          OK
        </Button>

        <Button
          onClick={() => handleCloseAction()}
          disabled={isDoing}
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
