import { IconButton, OutlinedInput } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showWarning } from "../lib/common";

const server = require("../lib/server");
//Xu ly 1 file attach
export function FileAttachControl({ data, onChange, accept, maxSize }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <OutlinedInput
        fullWidth
        readOnly
        value={data.FileName ? data.FileName : ""}
      />

      <Dropzone
        maxFiles={1}
        minSize={1}
        validator={(file) => {
          return false;
        }}
        accept={
          accept
            ? accept
            : {
                "application/pdf": [".pdf"],
                "image/png": [".png"],
                "image/jpeg": [".jpg"],
              }
        }
        onDrop={(acceptedFiles) => {
          var allow = true;
          if (maxSize) {
            acceptedFiles.map(
              (file) => (allow = allow && file.size <= maxSize)
            );
          }
          if (allow && onChange) {
            onChange(acceptedFiles);
          } else {
            showWarning("File không hợp lệ về kích thước!");
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <IconButton>
                <CloudUploadIcon
                  fontSize="small"
                  color="info"
                ></CloudUploadIcon>
              </IconButton>
            </div>
          </section>
        )}
      </Dropzone>
      {data.FileID ? (
        <IconButton
          onClick={() =>
            window.open(
              server.getRequest("HoSo/GetFile?FileID=" + data.FileID),
              "_blank"
            )
          }
        >
          <VisibilityIcon fontSize="small" color="success"></VisibilityIcon>
        </IconButton>
      ) : null}

      {data.FileName ? (
        <IconButton>
          <DeleteForeverIcon
            fontSize="small"
            color="error"
            onClick={() => {
              if (onChange) {
                onChange(null);
              }
            }}
          ></DeleteForeverIcon>
        </IconButton>
      ) : null}
    </Stack>
  );
}
