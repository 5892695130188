import React from "react";
import { Select, MenuItem } from "@mui/material";
import { display } from "@mui/system";

export default class MySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
   return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    return (
      <Select
        id="cbo"
        fullWidth
        disabled={this.props.disabled}
        value={this.props.value == undefined ? null : this.props.value}
        onChange={(event) => {
 
          if (this.props.onChange) {
            this.props.onChange(event);
          }
        }}
        error={this.props.error}
      >
        {this.props.options
          ? this.props.options.map((item, index) => {
              return (
                <MenuItem
                  key={item[this.props.optionValue]}
                  value={item[this.props.optionValue]}
                >
                  {item[this.props.optionLabel]}
                </MenuItem>
              );
            })
          : null}
      </Select>
    );
  }
}
