import { IconButton, OutlinedInput } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import { handleServerError } from "../lib/MyLib";
import { showWarning } from "../lib/common";
const server = require("../lib/server");

//Xu ly 1 file attach
export function MultiFileAttachControl({
  attachs,
  onChange,
  onRemove,
  accept,
  deleteFileAction,
  maxSize,
}) {
  let list = attachs ? attachs.concat([{}]) : [{}];
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false); //hien thi form xac nhan xoa file
  const [deleting, setDeleting] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState(null);
  return (
    <Stack direction="column">
      {list.map((data, index) =>
        data.FileName || data.FileID == undefined ? (
          <Stack direction={"row"} alignItems={"center"}>
            <OutlinedInput
              fullWidth
              readOnly
              value={data.FileName ? data.FileName : ""}
            />
            {data.FileID ? null : (
              <Dropzone
                maxFiles={10}
                minSize={1}
                validator={(file) => {
                  return false;
                }}
                accept={
                  accept
                    ? accept
                    : {
                        "application/pdf": [".pdf"],
                        "image/png": [".png"],
                        "image/jpeg": [".jpg"],
                      }
                }
                onDrop={(acceptedFiles) => {
                  var allow = true;
                  if (maxSize) {
                    acceptedFiles.map(
                      (file) => (allow = allow && file.size <= maxSize)
                    );
                  }
                  if (allow && onChange) {
                    onChange(acceptedFiles);
                  } else {
                    showWarning("File không hợp lệ về kích thước!");
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <IconButton>
                        <CloudUploadIcon
                          fontSize="small"
                          color="info"
                        ></CloudUploadIcon>
                      </IconButton>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
            {data.FileID ? (
              <IconButton
                onClick={() =>
                  window.open(
                    server.getRequest("HoSo/GetFile?FileID=" + data.FileID),
                    "_blank"
                  )
                }
              >
                <VisibilityIcon
                  fontSize="small"
                  color="success"
                ></VisibilityIcon>
              </IconButton>
            ) : null}

            {data.FileName ? (
              <IconButton>
                <DeleteForeverIcon
                  fontSize="small"
                  color="error"
                  onClick={() => {
                    if (onRemove !== undefined) {
                      onRemove(data, index); //remove
                    }
                  }}
                ></DeleteForeverIcon>
              </IconButton>
            ) : null}
          </Stack>
        ) : null
      )}
      {showConfirmDelete ? (
        <DeleteConfirmDialog
          open="true"
          title={"Xác nhận"}
          message={"Bạn muốn xóa file ?"}
          isDeleting={deleting}
          handleCloseAction={() => setShowConfirmDelete(false)}
          handleDeleteAction={() => {
            setDeleting(true);
            deleteFileAction(deletingItem.FileID)
              .then(() => {
                setDeleting(false);
                setShowConfirmDelete(false);
                onChange(null, deletingItem.Index); //remove file
              })
              .catch((e) => {
                handleServerError(e);
                setDeleting(false);
              });
          }}
        />
      ) : null}
    </Stack>
  );
}
