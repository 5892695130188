import React from "react";
import { OutlinedInput, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";

function textToTime(text) {
  var d = "";
  var count = 0;
  for (var i = 0, n = text.length; i < n; i++) {
    var ch = text.charAt(i);
    if (ch !== "/") {
      d += ch;
      //console.log("count=", count % 2);
      if (count % 2 == 1) {
        d += count == 1 ? ":" : count == 3 ? " " : "/";
      }
      count++;
      //console.log("ch=", ch, "d=", d);
    }
  }
  //console.log(text, d);
  return d;
}

const CustomInput = (params) => {
  const { state, setState } = params;
  //console.log(params);

  return (
    <TextField
      variant="outlined"
      autoComplete="off"
      placeholder="HH:mm dd/MM/yyyy"
      {...params}
      value={state.inputValue}
      onChange={(event) => {
        //console.log("onChange input:" + event.target.value, state);
        let v = event.target.value;
        var date = moment(v, "HH:mm DD/MM/YYYY");
        var newDate = null;
        if (!isNaN(date)) {
          newDate = new Date(date);
          let fmt = moment(newDate).format("HH:mm DD/MM/YYYY");
          //console.log(fmt);
          if (v !== fmt) {
            newDate = null;
          } else {
            v = fmt;
          }
        }
        let ns = {
          inputValue: v,
          value: newDate,
        };
        //console.log(ns);
        setState(ns);
      }}
      onBlur={() => {
        //console.log("sssssssssssssssss" + state.inputValue + "/");
        if (
          state.inputValue !== "" &&
          (state.value == null ||
            state.value == undefined ||
            state.value == "Invalid Date")
        ) {
          var now = new Date();
          var text = moment(now).format("HH:mm DD/MM/YYYY");
          //console.log("now=" + text);
          var str = textToTime(state.inputValue);
          text = str + text.substring(str.length);

          //console.log("date=" + text);
          var date = moment(text, "HH:mm DD/MM/YYYY");
          //console.log("set date=" + date);
          if (!isNaN(date)) {
            //console.log("set date=" + new Date(date));
            setState({ value: new Date(date), inputValue: text });
          } else {
            //console.log("set date=null");
            setState({ inputValue: "", value: null });
          }
        }
      }}
    />
  );
};
export default class MyDateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      inputValue: "",
    };
  }
  setValue(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    } else {
      this.setState({ value: value });
    }
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("get from props...." + nextProps.value);
    var value = nextProps.value;
    if (value !== prevState.value) {
      if (value == null || value == undefined || value == "Invalid Date") {
        //console.log("setnulllllll");
        return { value: null, inputValue: "" };
      }
      return {
        value: nextProps.value,
        inputValue: nextProps.value
          ? moment(nextProps.value).format("HH:mm DD/MM/YYYY")
          : "",
      };
    }
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  //https://codesandbox.io/p/sandbox/suspicious-snowflake-45wv6t?file=%2Fsrc%2FApp.js
  render() {
    //console.log("state=", this.state);
    return (
      <DateTimePicker
        autoFocus={this.props.autoFocus}
        format={"HH:mm DD/MM/YYYY"}
        disableOpenPicker={
          this.props.disableOpenPicker ? this.props.disableOpenPicker : false
        }
        selectedSections={null}
        value={this.state.value ? moment(this.state.value) : null}
        onChange={(newValue) => {
          //console.log("onChange dateime:" + newValue);
          if (newValue === "Invalid Date" || !newValue || isNaN(newValue)) {
            this.setState({ inputValue: "", value: null });
          } else {
            this.setState({
              inputValue: moment(newValue).format("HH:mm DD/MM/YYYY"),
              value: newValue,
            });
          }
        }}
        readOnly={this.props.readOnly}
        slots={{
          textField: CustomInput,
        }}
        slotProps={{
          textField: {
            state: this.state,
            setState: (s) => {
              //console.log("set=", s);
              this.setState(s);
              if (this.props.onChange) {
                this.props.onChange(s.value);
              }
            },
          },
          popper: {},
        }}
      />
    );
  }
}
