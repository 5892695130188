/* Hien thi giao dien form tim kiem */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
} from "@mui/material";

import { ToastContainer } from "react-toastify";
import MyDateEditor from "../components/MyDateEditor";
import { showError, showSuccess, handleServerError } from "../lib/common";

import EditFormConfig from "../DynamicForms/EditFormConfig";
import { HackFunctionList } from "../DynamicForms/FormHack";

import MyNumberEditor from "../components/MyNumberEditor";
import MySelectFilter from "../components/MySelectFilter";
import { FileAttachControl } from "../components/FileAttachControl";
import uuid from "react-uuid";
import { RichTreeView, TreeItem } from "@mui/x-tree-view";

const server = require("../lib/server");
const myLib = require("../lib/MyLib");

//tao cau truc tree items
function buildTreeItems(list) {
  var items = [];
  var stack = [];
  list.map((record) => {
    record.id = record.RTCD;
    record.label = record.RTCD + " - " + record.RTNM;
    if (myLib.isEmpty(record.GRCD)) {
      stack.splice(0, 0, [record, null]);
    }
  });
  var count = 1;
  while (stack.length > 0 && count < 2000) {
    var group = stack[stack.length - 1];
    stack.splice(stack.length - 1, 1); //remove
    if (group[1] === null) {
      items.push(group[0]); //cho vao items cua tree
    }
    list.map((record) => {
      if (record.RTCD !== group[0].RTCD && record.GRCD === group[0].RTCD) {
        if (group[0].children === undefined) {
          group[0].children = [];
        }
        group[0].children.push(record);
        stack.push([record, group[0]]);
      }
    });
    count++;
  }

  return items;
}
function getTreeItemIds(list) {
  var items = [];
  list.map((record) => items.push(record.RTCD));
  return items;
}
//tra ve danh sach quyen phan cach bang dau cham phay
function getRTCDs(items) {
  var rtcds = "";
  items.map((i)=>rtcds += i + ";");
  return rtcds;
}
function getTreeIdsFromRTCDs(rtcds) {
  var list = rtcds.split(";");
  return list;
}
/* KHAI BAO MAIN FUNCTION */
export default function NhomQuyenEditForm(props) {
  //cac bien dung chung trong form
  //cac bien cau hinh form
  let FormConfig = props.FormConfig; //khai bao cau hinh hien thi form
  let defaultValues = {}; //gia tri khoi tao
  let defaultDanhMuc = {};
  var hasDanhMuc = false;
  var danhMucNames = ""; //danh sach ten danh muc
  props.FormConfig.EditFields.map((field) => {
    if (field.DefaultValue !== undefined && field.DefaultValue !== null) {
      if (
        typeof field.DefaultValue === "string" &&
        field.DefaultValue.indexOf("@") === 0
      ) {
        if (field.DefaultValue == "@User.BranchCode") {
          field.DefaultValue = global.User.BranchCode; //ma don vi nguoi su dung
        }
        if (
          props.FilterData &&
          field.DefaultValue.indexOf("@FilterData.") == 0
        ) {
          //lay gia tri truyen vao tu props.FilterData
          var filterField = field.DefaultValue.split(".")[1];
          defaultValues[field.FieldName] = props.FilterData[filterField];
        }
      } else {
        if (field.DefaultValue === "thisyear()") {
          defaultValues[field.FieldName] = new Date().getFullYear();
          defaultValues[field.FieldName + "_Ten"] = new Date().getFullYear();
        } else {
          if (field.DataType == "Boolean") {
            defaultValues[field.FieldName] = field.DefaultValue + "" === "true";
          } else {
            if (
              field.DataType == "String" &&
              field.DefaultValue.indexOf("{") >= 0
            ) {
              var exp = field.DefaultValue.substring(
                1,
                field.DefaultValue.length - 1
              );
              var ps = exp.split(".");
              if (ps.length > 1 && ps[0] == "props") {
                if (ps.length > 2) {
                  defaultValues[field.FieldName] = props[ps[1]]
                    ? props[ps[1]][ps[2]]
                    : null; //lay tu props
                } else {
                  defaultValues[field.FieldName] = props[ps[1]]; //lay tu props
                }
              }
            } else {
              {
                defaultValues[field.FieldName] = field.DefaultValue;
              }
            }
          }
        }
      }
    }
    if (field.SourceName && field.SourceName !== "") {
      defaultDanhMuc[field.SourceName] = []; //khoi tao la array trong'
      hasDanhMuc = true;
      if (
        danhMucNames !== field.SourceName &&
        danhMucNames.indexOf(field.SourceName + ",") < 0 &&
        danhMucNames.indexOf("," + field.SourceName) < 0
      ) {
        danhMucNames += (danhMucNames !== "" ? "," : "") + field.SourceName;
      }
    }
  });
  const [showConfigForm, setShowConfigForm] = React.useState(false);
  //cac bien state tim kiem
  const [DanhMuc, setDanhMuc] = React.useState(defaultDanhMuc); // du lieu danh muc se su dung trong form

  const [dataChanged, setDataChanged] = React.useState(false); //du lieu edit co thay doi hay khong
  const [dataEdit, setDataEdit] = React.useState(
    props.edittingRecord
      ? { ...defaultValues, ...props.edittingRecord }
      : defaultValues
  ); //cau truc du lieu dang edit

  const [loading, setLoading] = React.useState(false); //trang thai loading du lieu
  const [saving, setSaving] = React.useState(false); //trang thai dang ghi du lieu
  //hien thi tree view
  const [dataRows, setDataRows] = React.useState([]); // danh sach quyen
  const [treeViewItems, setTreeViewItems] = React.useState([]);
  const [treeViewExpandItems, setTreeViewExpandItems] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
    setDataChanged(true);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj, selectedItem) {
    //call function can thiep vao gia tri neu co
    if (!myLib.isEmpty(props.FormConfig.EditOnChange)) {
      var func = HackFunctionList[props.FormConfig.EditOnChange];
      if (func) {
        obj = func(obj, dataEdit, selectedItem);
      }
    }
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
    setDataChanged(true);
  }

  //hook
  React.useEffect(() => {
    //thuc hien search on load form hoac khi co su thay doi ve phan trang va sap xep
    if (danhMucNames !== "") {
      setLoading(true);
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhMucNames,
          },
        })
        .then((response) => {
          setLoading(false);

          //xem co field nao su dung den danh muc khong
          //neu co danh muc nao filter theo truong thong tin khac thi tao danh muc do'
          var setValues = {};
          props.FormConfig.EditFields.map((field) => {
            if (field.SourceName && field.SourceName !== "") {
              var list = response[field.SourceName];

              //neu co gia tri default
              if (field.DefaultValue) {
                if (list.length > 0) {
                  if (field.DefaultValue == "@FirstRow") {
                    setValues = {
                      ...setValues,
                      [field.FieldName]: list[0][field.SourceValue],
                      [field.FieldName + "_Ten"]: list[0][field.SourceLabel],
                    };
                  }
                  //neu lay gia tri tu tieu chi tim kiem
                  if (
                    props.FilterData &&
                    typeof field.DefaultValue === "string" &&
                    field.DefaultValue.indexOf("@FilterData.") == 0
                  ) {
                    var filterField = field.DefaultValue.split(".")[1];
                    var filterValue = props.FilterData[filterField];
                    var found = list.find(
                      (item) => item[field.SourceValue] === filterValue
                    );
                    if (found) {
                      setValues = {
                        ...setValues,
                        [field.FieldName]: found[field.SourceValue],
                        [field.FieldName + "_Ten"]: found[field.SourceLabel],
                      };
                    }
                  }
                  if (field.DataType === "Int") {
                    var intValue = Number(field.DefaultValue);
                    var found = response[field.SourceName].find(
                      (item) => item[field.SourceValue] == intValue
                    );
                    if (found) {
                      setValues = {
                        ...setValues,
                        [field.FieldName]: intValue,
                        [field.FieldName + "_Ten"]: found[field.SourceLabel],
                      };
                    }
                  }
                }
              }
            }
          });
          var newEditValues = { ...dataEdit };
          //neu co thi gan gia tri
          if (Object.keys(setValues).length > 0) {
            saveData(setValues);
            newEditValues = { ...newEditValues, ...setValues }; //gop cac gia tri
          }
          //neu filter theo truong khac
          props.FormConfig.EditFields.map((field) => {
            if (field.SourceName && field.SourceName !== "") {
              if (field.SourceFilter) {
                var list = [];
                var all = response[field.SourceName];
                all.map((item) => {
                  if (
                    item[field.SourceFilter] ===
                    newEditValues[field.SourceFilterBy]
                  ) {
                    list.push(item);
                  }
                });
                response[field.SourceName + "_Filter"] = list; //tao danh muc filter
              }
            }
          });
          setDanhMuc(response);
        })
        .catch((error) => {
          handleServerError(error);
          setLoading(false);
        });
    }
    //load danh muc quyen theo phan mem
      server
        .post("Data/DoRequest", {
          Function: "Proc_UMS_SOFTRTS_GetBy",
          ThamSo: {
            SCD: props.edittingRecord ? props.edittingRecord.SCD : props.FilterData.SCD
          },
        })
        .then((response) => {
          setDataRows(response.UMS_SOFTRTS);
          setTreeViewItems(buildTreeItems(response.UMS_SOFTRTS));
          setTreeViewExpandItems(getTreeItemIds(response.UMS_SOFTRTS));
          //neu o che do edit
          if(props.edittingRecord) {
            setSelectedRows(getTreeIdsFromRTCDs(props.edittingRecord.RTCDS ?? ""));
          }
        })
        .catch((error) => {
          handleServerError(error);
        });
  }, []);
  const doReadAttachs = (fileObject) => {
    //thuc hien doc tiep cac file attachs neu co
    var prom = new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const raw = Buffer.from(text).toString("base64");
        doSaveUpload({
          FileID: uuid(),
          FileName: fileObject.name,
          FileContent: raw,
        });
      };
      fr.onerror = reject;
      fr.readAsArrayBuffer(fileObject);
    });
    //thuc hien viec xu ly
    prom
      .then((fileData) => {
        //thuc hien upload
        doSaveUpload(fileData);
      })
      .catch((e) => showError(e));
  };

  const doSaveUpload = (fileData) => {
    console.log(fileData);
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: FormConfig.SaveFunction,
        ThamSo: {
          ...dataEdit,
          FileObject: null,
          ...fileData,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        if (FormConfig.CloseAfterSaving) {
          props.close(true);
        }
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  //ham xu ly viec save du lieu
  const doSaveNoFile = () => {
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: FormConfig.SaveFunction,
        ThamSo: {
          ...dataEdit,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        if (FormConfig.CloseAfterSaving) {
          props.close(true);
        }
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  //thuc hien cap nhat
  const doSave = () => {
    if (dataEdit.FileObject) {
      doReadAttachs(dataEdit.FileObject);
    } else {
      doSaveNoFile();
    }
  };
  //ham xu ly viec close form
  const doClose = () => {
    props.close(false);
  };
  return (
    <Dialog maxWidth={FormConfig.DialogWidth ?? "lg"} open={true} fullWidth>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            {FormConfig.FormTitle}
          </Typography>
          <Stack direction="row" spacing={4}>
            {loading ? <CircularProgress color="warning" size={24} /> : null}
            {saving ? <CircularProgress color="inherit" size={24} /> : null}
            {global.hasRoles("KHTC.RX") ? (
              <Button
                variant="contained"
                onClick={() => setShowConfigForm(true)}
              >
                Config
              </Button>
            ) : null}
            <Button
              variant="contained"
              disabled={!dataChanged || saving}
              onClick={doSave}
            >
              Save
            </Button>

            <Button
              color="success"
              variant="contained"
              disabled={saving}
              onClick={doClose}
            >
              Thoát
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 400,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
            overflowY: "hidden",
            minHeight: FormConfig.MinHeight ?? 300,
          }}
        >
          <Grid container spacing={1} columns={24}>
            {props.FormConfig.EditFields.map((field) =>
              field.Hidden ? null : (
                <>
                  {field.LabelWidth ? (
                    <Grid item xs={field.LabelWidth}>
                      <FormLabel>{field.Label}</FormLabel>
                    </Grid>
                  ) : null}
                  <Grid item xs={field.InputWidth ?? 4}>
                    {field.SourceName && field.SourceName !== "" ? (
                      <MySelectFilter
                        fullWidth
                        autoFocus={field.AutoFocus}
                        options={
                          DanhMuc[
                            field.SourceFilter
                              ? field.SourceName + "_Filter"
                              : field.SourceName
                          ]
                        }
                        disabled={props.edittingRecord ? field.LockEdit : false}
                        optionLabel={field.SourceLabel}
                        optionValue={field.SourceValue}
                        value={{
                          [field.SourceValue]: dataEdit[field.FieldName],
                          [field.SourceLabel]:
                            dataEdit[field.FieldName + "_Ten"] ??
                            dataEdit[field.FieldName],
                        }}
                        onChange={(value, selectedItem) => {
                          var setValues = {
                            [field.FieldName]:
                              value !== null ? value[field.SourceValue] : null,
                            [field.FieldName + "_Ten"]:
                              value !== null ? value[field.SourceLabel] : null,
                          };

                          //kiem tra xem co phai auto load lai danh sach combo khac phu thuoc vao truong nay khong
                          var found = props.FormConfig.EditFields.find(
                            (item) =>
                              item.SourceFilterAuto &&
                              item.SourceFilterBy === field.FieldName
                          );
                          if (found) {
                            //xoa truong thong tin
                            setValues = {
                              ...setValues,
                              ...{
                                [found.FieldName]: null,
                                [found.FieldName + "_Ten"]: null,
                              },
                            };
                            //thiet lap lai du lieu danh muc filter
                            var list = [];
                            var all = DanhMuc[found.SourceName];
                            var filterValue =
                              value !== null ? value[field.SourceValue] : null;
                            all.map((item) => {
                              if (item[found.SourceFilter] === filterValue) {
                                list.push(item);
                              }
                            });
                            DanhMuc[found.SourceName + "_Filter"] = list; //tao danh muc filter
                          }
                          saveData(setValues, selectedItem);
                        }}
                      />
                    ) : field.DataType === "Date" ? (
                      <MyDateEditor
                        fullWidth
                        autoFocus={field.AutoFocus}
                        readOnly={field.ReadOnly}
                        disabled={props.edittingRecord ? field.LockEdit : false}
                        value={dataEdit[field.FieldName]}
                        onChange={(value) => {
                          saveField(field.FieldName, value);
                        }}
                      />
                    ) : field.DataType === "String" ? (
                      <OutlinedInput
                        fullWidth
                        autoFocus={field.AutoFocus}
                        disabled={props.edittingRecord ? field.LockEdit : false}
                        value={dataEdit[field.FieldName]}
                        readOnly={field.ReadOnly}
                        multiline={field.MultiLine}
                        onChange={(event) => {
                          saveField(
                            field.FieldName,
                            field.UpperCasing
                              ? myLib.toUpperCase(event.target.value)
                              : event.target.value
                          );
                        }}
                      ></OutlinedInput>
                    ) : field.DataType === "Boolean" ? (
                      <Checkbox
                        size="medium"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                        checked={dataEdit[field.FieldName]}
                        readOnly={true}
                        onChange={(event) => {
                          if (!field.ReadOnly) {
                            saveField(field.FieldName, event.target.checked);
                          }
                        }}
                      />
                    ) : field.DataType === "Int" ? (
                      <MyNumberEditor
                        fullWidth
                        scale={0}
                        disabled={props.edittingRecord ? field.LockEdit : false}
                        value={dataEdit[field.FieldName]}
                        onValueChange={(value) =>
                          saveField(field.FieldName, value)
                        }
                      ></MyNumberEditor>
                    ) : field.DataType === "File" ? (
                      <FileAttachControl
                        data={dataEdit}
                        disabled={props.edittingRecord ? field.LockEdit : false}
                        onChange={(files) => {
                          if (files) {
                            if (!files.length) {
                              saveData({
                                FileName: files[0].name,
                                FileObject: files[0],
                              });
                            } else {
                              console.log(files);
                              saveData({
                                FileName: files[0].name,
                                FileObject: files[0],
                              });
                            }
                          } else {
                            saveData({
                              FileID: null,
                              FileName: null,
                              FileObject: null,
                            });
                          }
                        }}
                      />
                    ) : null}
                  </Grid>
                </>
              )
            )}
          </Grid>
          <FormLabel><b>Danh sách quyền:</b></FormLabel>
          <RichTreeView
              items={treeViewItems}
              expandedItems={treeViewExpandItems}
              checkboxSelection={true}
              itemChildrenIndentation={24}
              multiSelect={true}
              selectedItems={selectedRows}
              onSelectedItemsChange={(event, items) => {
                setSelectedRows(items);
                saveField("RTCDS", getRTCDs(items));
                setDataChanged(true);
              }}
            />
        </div>
      </DialogContent>
      {
        /**Hien thi form cau hinh form edit */
        showConfigForm ? (
          <EditFormConfig
            FormID={props.FormID}
            FormConfig={FormConfig}
            close={() => setShowConfigForm(false)}
          />
        ) : null
      }
    </Dialog>
  );
}
