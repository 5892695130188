/* Hien thi giao dien form tim kiem */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import { showError, showSuccess, handleServerError } from "../lib/common";
import EditFormConfig from "../DynamicForms/EditFormConfig";
import MyNumberEditor from "../components/MyNumberEditor";
import MySelectFilter from "../components/MySelectFilter";
import { HackFunctionList } from "../DynamicForms/FormHack";
const server = require("../lib/server");
const myLib = require("../lib/MyLib");

/* KHAI BAO MAIN FUNCTION */
export default function HS_TheoDoiQuaHan(props) {
  //cac bien dung chung trong form
  //cac bien cau hinh form
  let FormConfig = props.FormConfig; //khai bao cau hinh hien thi form
  let defaultValues = {
    TaiKhoan: global.User.UserName,
    HS_TheoDoiQuaHan_Ship: [], //theo doi danh sach tau
    HS_TheoDoiQuaHan_NhomQL: [], //theo doi nhom quan ly thiet bi
  }; //gia tri khoi tao
  let defaultDanhMuc = {};
  var hasDanhMuc = false;
  var danhMucNames = ""; //danh sach ten danh muc
  props.FormConfig.EditFields.map((field) => {
    if (field.DefaultValue !== undefined && field.DefaultValue !== null) {
      if (
        typeof field.DefaultValue === "string" &&
        field.DefaultValue.indexOf("@") === 0
      ) {
        if (field.DefaultValue == "@User.BranchCode") {
          field.DefaultValue = global.User.BranchCode; //ma don vi nguoi su dung
        }
        if (
          props.FilterData &&
          field.DefaultValue.indexOf("@FilterData.") == 0
        ) {
          //lay gia tri truyen vao tu props.FilterData
          var filterField = field.DefaultValue.split(".")[1];
          defaultValues[field.FieldName] = props.FilterData[filterField];
        }
      } else {
        if (field.DefaultValue === "thisyear()") {
          defaultValues[field.FieldName] = new Date().getFullYear();
          defaultValues[field.FieldName + "_Ten"] = new Date().getFullYear();
        } else {
          if (field.DataType == "Boolean") {
            defaultValues[field.FieldName] = field.DefaultValue + "" === "true";
          } else {
            if (
              field.DataType == "String" &&
              field.DefaultValue.indexOf("{") >= 0
            ) {
              var exp = field.DefaultValue.substring(
                1,
                field.DefaultValue.length - 1
              );
              var ps = exp.split(".");
              if (ps.length > 1 && ps[0] == "props") {
                defaultValues[field.FieldName] = props[ps[1]]; //lay tu props
              }
            } else {
              {
                defaultValues[field.FieldName] = field.DefaultValue;
              }
            }
          }
        }
      }
    }
    if (field.SourceName && field.SourceName !== "") {
      defaultDanhMuc[field.SourceName] = []; //khoi tao la array trong'
      hasDanhMuc = true;
      if (
        danhMucNames !== field.SourceName &&
        danhMucNames.indexOf(field.SourceName + ",") < 0 &&
        danhMucNames.indexOf("," + field.SourceName) < 0
      ) {
        danhMucNames += (danhMucNames !== "" ? "," : "") + field.SourceName;
      }
    }
  });
  const [showConfigForm, setShowConfigForm] = React.useState(false);
  //cac bien state tim kiem
  const [DanhMuc, setDanhMuc] = React.useState(defaultDanhMuc); // du lieu danh muc se su dung trong form

  const [dataChanged, setDataChanged] = React.useState(false); //du lieu edit co thay doi hay khong
  const [dataEdit, setDataEdit] = React.useState(
    props.edittingRecord ? props.edittingRecord : defaultValues
  ); //cau truc du lieu dang edit
  const [loading, setLoading] = React.useState(false); //trang thai loading du lieu
  const [saving, setSaving] = React.useState(false); //trang thai dang ghi du lieu
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
    setDataChanged(true);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj, selectedItem) {
    //call function can thiep vao gia tri neu co
    if (!myLib.isEmpty(props.FormConfig.EditOnChange)) {
      var func = HackFunctionList[props.FormConfig.EditOnChange];
      if (func) {
        obj = func(obj, dataEdit, selectedItem);
      }
    }
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
    setDataChanged(true);
  }

  //hook
  React.useEffect(() => {
    //thuc hien search on load form hoac khi co su thay doi ve phan trang va sap xep
    if (danhMucNames !== "") {
      setLoading(true);
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhMucNames,
          },
        })
        .then((response) => {
          setLoading(false);

          //xem co field nao su dung den danh muc khong
          //neu co danh muc nao filter theo truong thong tin khac thi tao danh muc do'
          var setValues = {};
          props.FormConfig.EditFields.map((field) => {
            if (field.SourceName && field.SourceName !== "") {
              var list = response[field.SourceName];

              //neu co gia tri default
              if (field.DefaultValue) {
                if (list.length > 0) {
                  if (field.DefaultValue == "@FirstRow") {
                    setValues = {
                      ...setValues,
                      [field.FieldName]: list[0][field.SourceValue],
                      [field.FieldName + "_Ten"]: list[0][field.SourceLabel],
                    };
                  }
                  //neu lay gia tri tu tieu chi tim kiem
                  if (
                    props.FilterData &&
                    typeof field.DefaultValue === "string" &&
                    field.DefaultValue.indexOf("@FilterData.") == 0
                  ) {
                    var filterField = field.DefaultValue.split(".")[1];
                    var filterValue = props.FilterData[filterField];
                    var found = list.find(
                      (item) => item[field.SourceValue] === filterValue
                    );
                    if (found) {
                      setValues = {
                        ...setValues,
                        [field.FieldName]: found[field.SourceValue],
                        [field.FieldName + "_Ten"]: found[field.SourceLabel],
                      };
                    }
                  }
                  if (field.DataType === "Int") {
                    var intValue = Number(field.DefaultValue);
                    var found = response[field.SourceName].find(
                      (item) => item[field.SourceValue] == intValue
                    );
                    if (found) {
                      setValues = {
                        ...setValues,
                        [field.FieldName]: intValue,
                        [field.FieldName + "_Ten"]: found[field.SourceLabel],
                      };
                    }
                  }
                }
              }
            }
          });
          var newEditValues = { ...dataEdit };
          //neu co thi gan gia tri
          if (Object.keys(setValues).length > 0) {
            saveData(setValues);
            newEditValues = { ...newEditValues, ...setValues }; //gop cac gia tri
          }
          //neu filter theo truong khac
          props.FormConfig.EditFields.map((field) => {
            if (field.SourceName && field.SourceName !== "") {
              if (field.SourceFilter) {
                var list = [];
                var all = response[field.SourceName];
                all.map((item) => {
                  if (
                    item[field.SourceFilter] ===
                    newEditValues[field.SourceFilterBy]
                  ) {
                    list.push(item);
                  }
                });
                response[field.SourceName + "_Filter"] = list; //tao danh muc filter
              }
            }
          });
          setDanhMuc(response);
        })
        .catch((error) => {
          handleServerError(error);
          setLoading(false);
        });
    }
    //lay danh sach du lieu dang theo doi
    setLoading(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_HS_TheoDoiQuaHan_Get",
        ThamSo: {
          TaiKhoan: global.User.UserName,
        },
      })
      .then((response) => {
        setLoading(false);
        setDataEdit({...dataEdit, ...response });
      })
      .catch((error) => {
        handleServerError(error);
        setLoading(false);
      });
  }, []);
  //ham xu ly viec save du lieu
  const doSave = () => {
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: FormConfig.SaveFunction,
        ThamSo: {
          ...dataEdit,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        if (FormConfig.CloseAfterSaving) {
          props.close(true);
        }
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  //ham xu ly viec close form
  const doClose = () => {
    props.close(false);
  };
  return (
    <Dialog maxWidth={FormConfig.DialogWidth ?? "xl"} open={true} fullWidth>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            {FormConfig.FormTitle}
          </Typography>
          <Stack direction="row" spacing={4}>
            {loading ? <CircularProgress color="warning" size={24} /> : null}
            {saving ? <CircularProgress color="inherit" size={24} /> : null}
            {global.hasRoles("KHTC.RX") ? (
              <Button
                variant="contained"
                onClick={() => setShowConfigForm(true)}
              >
                Config
              </Button>
            ) : null}
            <Button
              variant="contained"
              disabled={!dataChanged || saving}
              onClick={doSave}
            >
              Save
            </Button>

            <Button
              color="success"
              variant="contained"
              disabled={saving}
              onClick={doClose}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 400,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
            overflowY: "hidden",
            minHeight: FormConfig.MinHeight ?? 300,
          }}
        >
          <Grid container spacing={1} columns={24}>
            {props.FormConfig.EditFields.map((field) =>
              field.Hidden ? null : (
                <>
                  <Grid item xs={field.LabelWidth ?? 4}>
                    <FormLabel>{field.Label}</FormLabel>
                  </Grid>
                  <Grid item xs={field.InputWidth ?? 4}>
                    {field.SourceName && field.SourceName !== "" ? (
                      <MySelectFilter
                        fullWidth
                        autoFocus={field.AutoFocus}
                        options={
                          DanhMuc[
                            field.SourceFilter
                              ? field.SourceName + "_Filter"
                              : field.SourceName
                          ]
                        }
                        optionLabel={field.SourceLabel}
                        optionValue={field.SourceValue}
                        value={{
                          [field.SourceValue]: dataEdit[field.FieldName],
                          [field.SourceLabel]:
                            dataEdit[field.FieldName + "_Ten"] ??
                            dataEdit[field.FieldName],
                        }}
                        onChange={(value, selectedItem) => {
                          var setValues = {
                            [field.FieldName]:
                              value !== null ? value[field.SourceValue] : null,
                            [field.FieldName + "_Ten"]:
                              value !== null ? value[field.SourceLabel] : null,
                          };

                          //kiem tra xem co phai auto load lai danh sach combo khac phu thuoc vao truong nay khong
                          var found = props.FormConfig.EditFields.find(
                            (item) =>
                              item.SourceFilterAuto &&
                              item.SourceFilterBy === field.FieldName
                          );
                          if (found) {
                            //xoa truong thong tin
                            setValues = {
                              ...setValues,
                              ...{
                                [found.FieldName]: null,
                                [found.FieldName + "_Ten"]: null,
                              },
                            };
                            //thiet lap lai du lieu danh muc filter
                            var list = [];
                            var all = DanhMuc[found.SourceName];
                            var filterValue =
                              value !== null ? value[field.SourceValue] : null;
                            all.map((item) => {
                              if (item[found.SourceFilter] === filterValue) {
                                list.push(item);
                              }
                            });
                            DanhMuc[found.SourceName + "_Filter"] = list; //tao danh muc filter
                          }
                          saveData(setValues, selectedItem);
                        }}
                      />
                    ) : field.DataType === "Date" ? (
                      <MyDateEditor
                        fullWidth
                        autoFocus={field.AutoFocus}
                        readOnly={field.ReadOnly}
                        value={dataEdit[field.FieldName]}
                        onChange={(value) => {
                          saveField(field.FieldName, value);
                        }}
                      />
                    ) : field.DataType === "String" ? (
                      <OutlinedInput
                        fullWidth
                        autoFocus={field.AutoFocus}
                        value={dataEdit[field.FieldName]}
                        readOnly={field.ReadOnly}
                        onChange={(event) => {
                          saveField(
                            field.FieldName,
                            field.UpperCasing
                              ? myLib.toUpperCase(event.target.value)
                              : event.target.value
                          );
                        }}
                      ></OutlinedInput>
                    ) : field.DataType === "Boolean" ? (
                      <Checkbox
                        size="medium"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                        checked={dataEdit[field.FieldName]}
                        readOnly={true}
                        onChange={(event) => {
                          if (!field.ReadOnly) {
                            saveField(field.FieldName, event.target.checked);
                          }
                        }}
                      />
                    ) : field.DataType === "Int" ? (
                      <MyNumberEditor
                        fullWidth
                        scale={0}
                        value={dataEdit[field.FieldName]}
                        onValueChange={(value) =>
                          saveField(field.FieldName, value)
                        }
                      ></MyNumberEditor>
                    ) : null}
                  </Grid>
                </>
              )
            )}
            <Grid item xs={24}>
              <Typography variant="h6">Danh sách tàu:</Typography>
            </Grid>
            {
              /**Hien thi danh sach tau */
              dataEdit.HS_TheoDoiQuaHan_Ship.map((ship) => (
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ship.TheoDoi}
                        onChange={(event) => {
                          ship.TheoDoi = event.target.checked;
                          var newData = { ...dataEdit };
                          setDataEdit(newData);
                          setDataChanged(true);
                        }}
                      />
                    }
                    label={ship.ShipName}
                  />
                </Grid>
              ))
            }
            <Grid item xs={24}>
              <Typography variant="h6">Danh sách bộ phận quản lý:</Typography>
            </Grid>
            {
              /**Hien thi danh sach tau */
              dataEdit.HS_TheoDoiQuaHan_NhomQL.map((ship) => (
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ship.TheoDoi}
                        onChange={(event) => {
                          ship.TheoDoi = event.target.checked;
                          var newData = { ...dataEdit };
                          setDataEdit(newData);
                          setDataChanged(true);
                        }}
                      />
                    }
                    label={ship.TenBoPhanQL}
                  />
                </Grid>
              ))
            }
          </Grid>
        </div>
      </DialogContent>
      {
        /**Hien thi form cau hinh form edit */
        showConfigForm ? (
          <EditFormConfig
            FormID={props.FormID}
            FormConfig={FormConfig}
            close={() => setShowConfigForm(false)}
          />
        ) : null
      }
    </Dialog>
  );
}
