import React from "react";
import { OutlinedInput, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

function textToTime(text) {
  var d = "";
  var count = 0;
  var now = new Date();
  var today = moment(now).format("DD/MM/YYYY");
  var ret = "";
  var j = 0,
    count = text.length;
  var DDMM = "",
    YY = "";
  for (var i = 0, n = today.length; i < n; ) {
    if (j < count) {
      d = text.charAt(j);
      j++;
      if (d !== "/") {
        if (DDMM.length < 5) {
          DDMM += d;
          i++;
          if (DDMM.length == 2) {
            DDMM += "/";
            i++;
          }
        } else {
          YY += d;
          i++;
        }
      }
    } else {
      ret += today.charAt(i);
      i++;
    }
  }
  while (DDMM.length < 5) {
    DDMM += today.charAt(DDMM.length);
  }
  if (YY.length < 4) {
    YY =
      YY.length == 3
        ? "2"
        : (YY.length == 2
            ? "20"
            : YY.length == 1
            ? "200"
            : now.getFullYear() + "") + YY;
  }
  //console.log(ret, "ddmm=", DDMM, "yy=", YY);
  return DDMM + "/" + YY;
}

const CustomInput = (params) => {
  const { state, setState } = params;
  //console.log(params);

  return (
    <TextField
      variant="outlined"
      fullWidth
      autoComplete="off"
      placeholder="dd/mm/yyyy"
      {...params}
      value={state.inputValue}
      onChange={(event) => {
        //console.log("onChange input:" + event.target.value, state);
        let v = event.target.value;
        var date = moment(v, "DD/MM/YYYY");
        var newDate = null;
        if (!isNaN(date)) {
          newDate = new Date(date);
          let fmt = moment(newDate).format("DD/MM/YYYY");
          //console.log(fmt);
          if (v !== fmt) {
            newDate = null;
          } else {
            v = fmt;
          }
        }
        let ns = {
          inputValue: v,
          value: newDate,
        };
        //console.log(ns);
        setState(ns);
      }}
      onBlur={() => {
        //console.log("sssssssssssssssss" + state.inputValue + "/");
        if (
          state.inputValue !== "" &&
          (state.value == null ||
            state.value == undefined ||
            state.value == "Invalid Date")
        ) {
          var now = new Date();
          var text = moment(now).format("DD/MM/YYYY");
          //console.log("now=" + text);
          var str = textToTime(state.inputValue);
          text = str + text.substring(str.length);
          //console.log("date=" + text);
          var date = moment(text, "DD/MM/YYYY");
          //console.log("set date=" + date);
          if (!isNaN(date)) {
            //console.log("set date=" + new Date(date));
            setState({ value: new Date(date), inputValue: text });
          } else {
            //console.log("set date=null");
            setState({ inputValue: "", value: null });
          }
        }
      }}
      error={params.error}
    />
  );
};
export default class MyDateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      inputValue: "",
    };
  }
  setValue(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    } else {
      this.setState({ value: value });
    }
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("get from props...." + nextProps.value);
    var value = nextProps.value;
    if (value !== prevState.value) {
      if (value == null || value == undefined || value == "Invalid Date") {
        //console.log("setnulllllll");
        return { value: null, inputValue: "" };
      }
      return {
        value: nextProps.value,
        inputValue: nextProps.value
          ? moment(nextProps.value).format("DD/MM/YYYY")
          : "",
      };
    }
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  //https://codesandbox.io/p/sandbox/suspicious-snowflake-45wv6t?file=%2Fsrc%2FApp.js
  render() {
    //console.log("state=", this.state);
    return (
      <DatePicker
        autoFocus={this.props.autoFocus}
        format={"DD/MM/YYYY"}
        disableOpenPicker={
          (this.props.disableOpenPicker
            ? this.props.disableOpenPicker
            : false) || this.props.readOnly
        }
        selectedSections={null}
        value={this.state.value ? moment(this.state.value) : null}
        onChange={(newValue) => {
          var newState = null;
          //console.log("onChange dateime:" + newValue);
          if (newValue === "Invalid Date" || !newValue || isNaN(newValue)) {
            //console.log("xxxx", newValue);
            newState = { inputValue: "", value: null };
            this.setState(newState);
          } else {
            //console.log("zzzzzzz", newValue);
            newState = {
              inputValue: moment(newValue).format("DD/MM/YYYY"),
              value: new Date(newValue),
            };
            this.setState(newState);
          }
          if (this.props.onChange) {
            this.props.onChange(newState.value);
          }
        }}
        readOnly={this.props.readOnly}
        slots={{
          textField: CustomInput,
        }}
        slotProps={{
          textField: {
            state: this.state,
            setState: (s) => {
              //console.log("set=", s);
              this.setState(s);
              if (this.props.onChange) {
                this.props.onChange(s.value);
              }
            },
            error: this.props.error,
          },
          popper: {},
        }}
        error={this.props.error}
      />
    );
  }
}
