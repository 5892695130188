/* Cau hinh danh sach field */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import { DataGrid, viVN } from "@mui/x-data-grid";
import { showError, showSuccess, handleServerError } from "../lib/common";
import FieldConfigForm from "./FieldConfigForm";
const server = require("../lib/server");
const myLib = require("../lib/MyLib");

/* KHAI BAO MAIN FUNCTION */
export default function ListFieldConfig(props) {
  //cac bien cau hinh form
  let FormConfig = props.FormConfig; //khai bao cau hinh hien thi form
  let FormType = FormConfig.FormType; // kieu form
  //cac bien state tim kiem
  const [fieldList, setFieldList] = React.useState(
    FormType == "Search" ? FormConfig.SearchConfig.Fields : []
  );

  const [dataChanged, setDataChanged] = React.useState(false); //du lieu edit co thay doi hay khong
  const [dataEdit, setDataEdit] = React.useState(
    props.edittingRecord ? props.edittingRecord : {}
  ); //cau truc du lieu dang edit
  const [loading, setLoading] = React.useState(false); //trang thai loading du lieu
  const [saving, setSaving] = React.useState(false); //trang thai dang ghi du lieu
  //hien thi form edit field
  const [showFieldConfig, setShowFieldConfig] = React.useState(false);
  const [selectedField, setSelectedField] = React.useState(null);
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
    setDataChanged(true);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj) {
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
    setDataChanged(true);
  }

  //hook
  React.useEffect(() => {
    //thuc hien search on load form hoac khi co su thay doi ve phan trang va sap xep
  }, []);
  //ham xu ly viec save du lieu
  const doSave = () => {
    setSaving(true);
    //chuan hoa lai gia tri defaultvalue cua cac field
    fieldList.map(
      (field) =>
        (field.DefaultValue =
          field.DataType === "Boolean" &&
          !myLib.isEmpty(field.DefaultValue)
            ? field.DefaultValue + "" === "true"
            : field.DefaultValue)
    );
    server
      .post("Data/DoRequest", {
        Function: "Proc_DA_Forms_Update",
        ThamSo: {
          FormID: props.FormID,
          FieldList: fieldList, //danh sach truong
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        props.close(false);
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  //ham xu ly viec close form
  const doClose = () => {
    props.close(false);
  };

  return (
    <Dialog maxWidth={FormConfig.DialogWidth ?? "xl"} open={true}>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            Danh sách trường
          </Typography>
          <Stack direction="row" spacing={4}>
            {loading ? <CircularProgress color="warning" size={24} /> : null}
            {saving ? <CircularProgress color="inherit" size={24} /> : null}
            <Button
              variant="contained"
              disabled={!dataChanged || saving}
              onClick={doSave}
            >
              Lưu
            </Button>

            <Button
              color="success"
              variant="contained"
              disabled={saving}
              onClick={doClose}
            >
              Thoát
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 400,
            minHeight: 30,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
          }}
        >
          <Grid container spacing={1} columns={12}>
            {fieldList.map((field, index) => (
              <>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setShowFieldConfig(true);
                      setSelectedField(field);
                    }}
                  >
                    {field.FieldName}
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(fieldList);
                      list.splice(index, 1);
                      setFieldList(list);
                      setDataChanged(true);
                    }}
                  >
                    Xóa
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(fieldList);
                      list.splice(index, 1);
                      if (index == 0) {
                        list.push(field);
                      } else {
                        list.splice(index - 1, 0, field);
                      }
                      setFieldList(list);
                      setDataChanged(true);
                    }}
                  >
                    Lên
                  </Button>
                </Grid>
              </>
            ))}

            <Grid item xs={2}>
              <Button
                color="primary"
                onClick={() => {
                  var newList = [].concat(fieldList);
                  newList.push({
                    FieldName: "Xxxx",
                  });
                  setFieldList(newList);
                  setDataChanged(true);
                }}
              >
                Thêm
              </Button>
            </Grid>
          </Grid>
        </div>
        {
          /*Hien thi form config field */
          showFieldConfig ? (
            <FieldConfigForm
              FieldConfig={selectedField}
              save={(field) => {
                //tim truong va thay the
                var list = [].concat(fieldList);
                var index = list.findIndex(
                  (item) => item.FieldName === selectedField.FieldName
                );
                list.splice(index, 1, field);
                setFieldList(list);
                setShowFieldConfig(false);
                setDataChanged(true);
              }}
              close={() => setShowFieldConfig(false)}
            />
          ) : null
        }
      </DialogContent>
    </Dialog>
  );
}
